<template>
  <div class="forget">
    <div class="forgetBox g-center gc-000 ">
      <div class="forgetHeader g-flex g-justify-space-between gmb-40">
        <span class="gfs-28">忘记密码</span>
        <span class="g-pointer" @click="back">返回</span>
      </div>
      <div v-if="step==1">
        <a-input v-model="mobile" placeholder="请输入手机号" size="large"></a-input>
        <div class="gmv-15 gc-red">{{errMsg}}</div>
        <a-button type="primary" block shape="round" size="large" @click="getCodeToMobile" :disabled="loading">下一步</a-button>
      </div>
      <div v-if="step==2">
        <codeInput @sendCode="sendCode"></codeInput>
        <div class="gmv-15 gc-red">{{errMsg}}</div>
        <div class="gtc gmt-40 g-pointer" @click="getCodeToMobile" :disabled="loading">重新获取</div>
      </div>
      <div v-if="step==3">
        <a-input-password type="password" v-model="password" placeholder="8-16位,可包含字母大小写、数字、下划线" size="large"></a-input-password>
        <div class="gmv-15 gc-red">{{errMsg}}</div>
        <a-button type="primary" block shape="round" size="large" @click="resetPwd">确定</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getCodeToMobile, resetPwd, checkCode } from "@/api/login";
import codeInput from "./components/codeInput";
export default {
  components: { codeInput },
  data() {
    return {
      mobile: "",
      password: "",
      errMsg: "",
      code: "",
      step: 1,
      loading: false,
    };
  },
  methods: {
    //获取验证码
    getCodeToMobile() {
      this.errMsg = "";
      if (!this.mobile) {
        this.errMsg = "输入内容不得为空！";
        return;
      }
      this.loading = true;
      getCodeToMobile(this.mobile).then((res) => {
        if (res.code !== 200) {
          if (this.step == 1) {
            this.errMsg = res.msg;
          }
        } else {
          this.step = 2;
          this.errMsg = "";
          this.$message.success("发送成功");
        }
        this.loading = false;
      });
    },
    //发送验证码
    sendCode(code) {
      this.code = "";
      checkCode({
        code,
        mobile: this.mobile,
      }).then((res) => {
        if (res.code == 200) {
          this.code = code;
          this.errMsg = "";
          this.step = 3;
        } else {
          this.errMsg = res.msg;
        }
      });
    },
    //重置密码
    resetPwd() {
      this.errMsg = "";
      if (!this.password) {
        this.errMsg = "请输入新密码";
        return;
      }
      let reg = /\w{8,16}/;
      if (!reg.test(this.password)) {
        this.errMsg = "8-16位,可包含字母大小写、数字、下划线";
        return;
      }
      resetPwd({
        code: this.code,
        mobile: this.mobile,
        password: this.password,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("重置密码成功");
          this.$router.push("/login");
        } else {
          this.errMsg = res.msg;
        }
      });
    },
    back() {
      if (this.step == 1) {
        this.$router.back();
      } else {
        this.step--;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.forget {
  .forgetBox {
    width: 368px;
    margin-top: 130px;
    .forgetHeader {
      align-items: flex-end;
    }
  }
}
</style>